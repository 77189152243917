import { computed, reactive } from '@nuxtjs/composition-api';
import { StateInterface, UseUiStateInterface } from '~/composables/useUiState/useUiState';
import useStore from '../useStore';
import { useCustomerOrderStore } from '~/modules/customer/stores/customerOrder';

const state = reactive<StateInterface>({
  isCartSidebarOpen: false,
  isSearchModalOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isCancelOrderReasonModalOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): UseUiStateInterface {
  const customerOrder = useCustomerOrderStore();

  const toggleBodyScroll = () => {
    if (document && !document.getElementsByTagName('html')[0].classList.contains('no-scroll')) {
      document.getElementsByTagName('html')[0].classList.add('no-scroll');
    } else {
      document.getElementsByTagName('html')[0].classList.remove('no-scroll');
    }
  };
  
  const toggleBodyBlur = () => {
    if (document && !document.getElementsByTagName('html')[0].classList.contains('blur-wrapper')) {
      document.getElementsByTagName('html')[0].classList.add('blur-wrapper');
    } else {
      document.getElementsByTagName('html')[0].classList.remove('blur-wrapper');
    }
  };

  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
    toggleBodyScroll();
  };

  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
    toggleBodyScroll();
  };
  
  const toggleSearchModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isSearchModalOpen = !state.isSearchModalOpen;
    toggleBodyScroll();
  };
  
  const showCartSidebar = () => {
    if (state.isSearchModalOpen) toggleSearchModal();
    state.isCartSidebarOpen = true;
    if (document) {
      document.getElementsByTagName('html')[0].classList.add('no-scroll');
    }
  };
  
  const hideSearchModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isSearchModalOpen = false;
    if (document) {
      document.getElementsByTagName('html')[0].classList.remove('no-scroll');
    }
  };
  
  const showSearchModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isSearchModalOpen = true;
    if (document) {
      document.getElementsByTagName('html')[0].classList.add('no-scroll');
    }
  };

  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
    toggleBodyScroll();
  };

  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
    toggleBodyScroll();
    toggleBodyBlur();
  };

  const toggleCancelOrderReasonModal = (orderId: String) => {
    customerOrder.setActiveOrderNumber(orderId);
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCancelOrderReasonModalOpen = !state.isCancelOrderReasonModalOpen;

    toggleBodyScroll();
    toggleBodyBlur();
  };

  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
    toggleBodyScroll();
  };

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  return {
    isMobileMenuOpen: computed(() => state.isMobileMenuOpen),
    isCartSidebarOpen: computed(() => state.isCartSidebarOpen),
    isSearchModalOpen: computed(() => state.isSearchModalOpen),
    isWishlistSidebarOpen: computed(() => state.isWishlistSidebarOpen),
    isLoginModalOpen: computed(() => state.isLoginModalOpen),
    isCancelOrderReasonModalOpen: computed(() => state.isCancelOrderReasonModalOpen),
    isNewsletterModalOpen: computed(() => state.isNewsletterModalOpen),
    isCategoryGridView: computed(() => state.isCategoryGridView),
    isWishlistGridView: computed(() => state.isWishlistGridView),
    isFilterSidebarOpen: computed(() => state.isFilterSidebarOpen),
    toggleMobileMenu,
    toggleCartSidebar,
    toggleSearchModal,
    hideSearchModal,
    showSearchModal,
    showCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleCancelOrderReasonModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
  };
}

export default useUiState;
export * from './useUiState';
