import { defineStore } from 'pinia';
import useCmsBlocks from '~/composables/useCmsBlocks';

interface CmsBlocksState {
    blocks: any;
}

export const useCmsBlocksStore = defineStore('cmsBlocks', {
  state: (): CmsBlocksState => ({
    blocks: null,
  }),
  actions: {
    async load() {
      this.blocks = await useCmsBlocks([
        "ads-block-1",
        "ads-block-2",
        "ads-block-3",
        "ads-block-4",
        "ads-block-5",
        "ads-block-6",
        "ads-block-7",
        "ads-block-8",
        "ads-block-mobile-tablets",
        "ads-block-computer-peripherals",
        "ads-block-wearables",
        "ads-block-accessories",
        "ads-block-personal-care",
        "ads-block-kitchen-appliances",
        "banner-block-1",
        "banner-block-2",
        "banner-block-3",
        "banner-block-4",
        "banner-after-computer-peripherals",
        "banner-after-accessories",
        "available-emi-options",
        "manufacturers",
        "social-media-links",
        "footer-payment-options",
        "hero-slider",
        "footer_links_block"
      ]);
    },
  }

});
