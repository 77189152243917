import { ref, onMounted, useContext } from '@nuxtjs/composition-api';
import { useApi } from '~/composables/useApi';
import cmsBlocksQuery from './cmsBlocks.gql';

type CmsBlockItems = {
  identifier: string
  title: string
  content: string
}

type CmsBlockQueryResponse = {
  cmsBlocks: {
    items: CmsBlockItems[]
  }
};

export async function useCmsBlocks(identifier: string[]) {
  const cmsBlocks = ref();
  const { app } = useContext();
  const cmsBlockLoading = ref(true);

  try {
    const { data, loading } = await app.context.$vsf.$magento.api.customQuery({
      query: cmsBlocksQuery,
      queryVariables: {
        identifier
      }
    });

    cmsBlocks.value = data?.cmsBlocks?.items;
    cmsBlockLoading.value = loading;
  } catch (err) {

    cmsBlocks.value = null;
  }
  
  return {
    cmsBlocks,
    cmsBlockLoading
  };
};

export default useCmsBlocks;
