import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4225d4aa = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _b8a72f46 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _4909a143 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _191313f6 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _907b33fe = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _32976cea = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _07be4b84 = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _4b6cbf77 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _f661f9be = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _d52a9c36 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _31b8f31e = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _0d525889 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _138d6c5f = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _5bc27a2a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _701845b6 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _75325cae = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _69e68265 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _2355f620 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _d1aec91c = () => interopDefault(import('../modules/catalog/pages/search-results.vue' /* webpackChunkName: "" */))
const _813c2d76 = () => interopDefault(import('../modules/catalog/pages/products.vue' /* webpackChunkName: "" */))
const _771e5306 = () => interopDefault(import('../pages/ResetPasswordPage.vue' /* webpackChunkName: "" */))
const _2dcead90 = () => interopDefault(import('../pages/StoreLocator.vue' /* webpackChunkName: "" */))
const _79765d52 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "" */))
const _294147a2 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _6743c354 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _4225d4aa,
    name: "checkout",
    children: [{
      path: "user-account",
      component: _b8a72f46,
      name: "user-account"
    }, {
      path: "shipping",
      component: _4909a143,
      name: "shipping"
    }, {
      path: "billing",
      component: _191313f6,
      name: "billing"
    }, {
      path: "payment",
      component: _907b33fe,
      name: "payment"
    }, {
      path: "thank-you",
      component: _32976cea,
      name: "thank-you"
    }]
  }, {
    path: "/cart",
    component: _07be4b84,
    name: "cart"
  }, {
    path: "/customer",
    component: _4b6cbf77,
    meta: {"titleLabel":"My Account"},
    name: "customer",
    children: [{
      path: "my-profile",
      component: _f661f9be,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile"
    }, {
      path: "addresses-details",
      component: _d52a9c36,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "addresses-details/create",
      component: _31b8f31e,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _0d525889,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "my-newsletter",
      component: _138d6c5f,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter"
    }, {
      path: "my-wishlist",
      component: _5bc27a2a,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist"
    }, {
      path: "order-history",
      component: _701845b6,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "order-history/:orderId",
      component: _75325cae,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }, {
      path: "my-reviews",
      component: _69e68265,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews"
    }, {
      path: "/reset-password",
      component: _2355f620,
      alias: "/customer/account/createPassword",
      name: "reset-password"
    }]
  }, {
    path: "/search-results",
    component: _d1aec91c,
    name: "search-results"
  }, {
    path: "/new-products",
    component: _813c2d76,
    name: "new-products"
  }, {
    path: "/big-deal-products",
    component: _813c2d76,
    name: "big-deal-products"
  }, {
    path: "/change-password",
    component: _771e5306,
    name: "change-password"
  }, {
    path: "/store-locator",
    component: _2dcead90,
    name: "store-locator"
  }, {
    path: "/:slug+",
    component: _79765d52,
    name: "page"
  }, {
    path: "/",
    component: _294147a2,
    name: "home"
  }, {
    path: "/Cms",
    component: _6743c354,
    name: "Cms"
  }, {
    path: "/Home",
    component: _294147a2,
    name: "Home"
  }, {
    path: "/Page",
    component: _79765d52,
    name: "Page"
  }, {
    path: "/ResetPasswordPage",
    component: _771e5306,
    name: "ResetPasswordPage"
  }, {
    path: "/StoreLocator",
    component: _2dcead90,
    name: "StoreLocator"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
