

















import { SfImage } from '@storefront-ui/vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'HeaderLogo',
  components: { SvgImage, SfImage },
  setup() {
    const { config } = useConfig();

    const logoSrc = computed(() => {
      return '/vda-logo.svg';
    });

    const logoWidth = computed(
      () => config.value.logo_width || '320',
    );

    const logoHeight = computed(
      () => config.value.logo_height || '63',
    );

    const logoAlt = computed(
      () => config.value.logo_alt || 'Venu\'s Digital Aracade',
    );

    return {
      logoAlt,
      logoHeight,
      logoSrc,
      logoWidth,
    };
  },
});
