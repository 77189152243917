



























import { useRoute, defineComponent, onMounted } from '@nuxtjs/composition-api';
import { useUiState, useUser } from '~/composables';
import { useCmsBlocksStore } from '~/stores/cmsBlocks';
import LazyHydrate from 'vue-lazy-hydration';
import AppHeader from '~/components/AppHeader.vue';
import BottomNavigation from '~/components/BottomNavigation.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    BottomNavigation,
    IconSprite,
    AppFooter: () => import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
    WishlistSidebar: () => import(/* webpackPrefetch: true */ '~/modules/wishlist/components/WishlistSidebar.vue'),
    LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/LoginModal.vue'),
    CancelOrderReasonModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/pages/MyAccount/OrderHistory/SingleOrder/CancelOrderReasonModal.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },

  setup() {
    const route = useRoute(); 
    const { isAuthenticated } = useUser();
    const {
      isCartSidebarOpen, isWishlistSidebarOpen, isLoginModalOpen, toggleLoginModal, 
      toggleCancelOrderReasonModal, isCancelOrderReasonModalOpen
    } = useUiState();
    const cmsBlocksStore = useCmsBlocksStore();
    cmsBlocksStore.load();   

    onMounted(() => {
      toggleLoginModalIfNeeded();
    })

    const toggleLoginModalIfNeeded = (() => {
      if (isAuthenticated.value) {
        return;
      }

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const action = urlParams.get('action')

      if('register' == action || 'login' == action) {
        toggleLoginModal();
      }
    });

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      isCancelOrderReasonModalOpen,
      toggleCancelOrderReasonModal,
      route,
    };
  },

  head: {
    // link: [{ rel: 'stylesheet', href: '/_nuxt/fonts.css' }],
  },
});
