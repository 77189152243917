import { defineStore } from 'pinia';
import type { Scalars, PaymentMethodInput } from '~/modules/GraphQL/types';

interface CheckoutState {
  paymentMethod: PaymentMethodInput | null;
  cartId: Scalars['ID'],
}

export const useCheckoutStore = defineStore('Checkout', {
  state: (): CheckoutState => ({
    paymentMethod: null,
    cartId: ''
  }),
});
