export interface SortingModel {
  selected: string,
  options: SortingOption[]
}

export interface SortingOption {
  label: string,
  value: SortingOptionsValuesEnum
}

export enum SortingOptionsValuesEnum {
  PRICE_ASC = 'price_ASC',
  PRICE_DESC = 'price_DESC',
  RELEVANCE_DESC = 'relevance_DESC',
}

export const sortingOptions: SortingOption[] = [
  {
    label: 'Most Relevant',
    value: SortingOptionsValuesEnum.RELEVANCE_DESC,
  },
  {
    label: 'Price High to Low',
    value: SortingOptionsValuesEnum.PRICE_DESC,
  },
  {
    label: 'Price Low to High',
    value: SortingOptionsValuesEnum.PRICE_ASC,
  },
];