import { defineStore } from 'pinia';

interface customerOrder {
  activeOrderNumber: String| null,
}

export const useCustomerOrderStore = defineStore('customer-order', {
  state: (): customerOrder => ({
    activeOrderNumber: null,
  }),
  actions: {
    setActiveOrderNumber(activeOrderNumber: String) {
      this.activeOrderNumber = activeOrderNumber;
    },
    unsetActiveOrderNumber() {
        this.activeOrderNumber = null;
    }
  },
});
