export default `
    query($identifier: [String]) { 
        cmsBlocks(identifiers: $identifier) {
            items {
                identifier
                title
                content
            }
        }
    }
`;
