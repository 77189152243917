
























import { defineComponent, ref, useFetch } from "@nuxtjs/composition-api";
import { useProduct } from "~/composables";
import { Products } from "~/modules/GraphQL/types";

export default defineComponent({
  name: "RenderComponents",
  components: {
    CmsBlocks: () =>
      import(/* webpackPrefetch: true */ "~/components/CmsBlocks.vue"),
    ProductPanel: () =>
      import(/* webpackPrefetch: true */ "~/components/ProductPanel.vue"),
  },
  props: {
    configurations: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { getProductList, loading, getProductPath } = useProduct();
    const products = ref([]);

    useFetch(async () => {
      if ("products" !== props.configurations.type) {
        return;
      }

      const productList: Products = (await getProductList({
        pageSize: 10,
        filter: props.configurations.filter,
        sort: props.configurations.sort
      })) as unknown as Products;

      if (productList?.items?.length) {
        products.value = productList.items;
      }
    });

    return {
      loading,
      products,
      getProductPath,
    };
  },
});
