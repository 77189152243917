


























































































































































import {
  SfOverlay, SfHeader, SfButton, SfBadge, SfLink, SfIcon
} from '@storefront-ui/vue';

import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useFetch,
} from '@nuxtjs/composition-api';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import {
  useUiHelpers,
  useUiState,
} from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import HeaderLogo from '~/components/HeaderLogo.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { useTopBar } from './TopBar/useTopBar';
import { Wishlist } from '../modules/GraphQL/types';
import { Logger } from '~/helpers/logger';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export default defineComponent({
  components: {
    HeaderNavigation,
    SfHeader,
    SfOverlay,
    HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
    SfLink,
    SfIcon,
    CurrencySelector: () => import('~/components/CurrencySelector.vue'),
    StoreSwitcher: () => import('~/components/StoreSwitcher.vue'),
    SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    SearchResults: () => import(
      /* webpackPrefetch: true */ '~/components/Header/SearchBar/SearchResults.vue'
    ),
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { toggleCartSidebar, toggleWishlistSidebar, toggleLoginModal, isSearchModalOpen, showSearchModal, hideSearchModal } = useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated, load: loadUser } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
    const { categories: categoryList, load: categoriesListLoad } = useCategory();

    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    const isAllCatOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);
    const searchLoading = ref(false);
    const searchTerm = ref('');

    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(() => wishlistStore.wishlist?.items_count ?? 0);

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const accountIcon = computed(() => (isAuthenticated.value ? 'profile_fill' : 'profile'));
    const categoryTree = ref<CategoryTree[]>([]);
    const featuredCategories = ref<CategoryTree[]>([]);

    const clearSearchValue = ref(false);
    
    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        hideSearchModal();
        toggleLoginModal();
      }
    };

    const handleCartClick = async () => {
      hideSearchModal();
      toggleCartSidebar();
    };

    const updateCatDropdownStatus = (categoryStatus) => {
      isAllCatOpen.value = categoryStatus;
    }

    const storeLocatorClickEvent = async () => {
      router.push('/store-locator');
    };

    const openSupportLink = async () => {
      document.location.href = 'tel:917994610044'
    };

    useFetch(async () => {
      console.log(isAuthenticated.value);
      await loadUser();
      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = categoryList.value?.[0]?.children;
      
      categoryList.value?.[0]?.children.forEach((category) =>{
        getIncludedInMenuCategories(category)
      })
    });
    
    onMounted(async () => {
      // Check if the vsf-customer cookie is set
      const customerStore = useCustomerStore();
      if (!app.$vsf.$magento.config.state.getCustomerToken()) {
        customerStore.setIsLoggedIn(false);
      }
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        // eslint-disable-next-line promise/catch-or-return
        await loadWishlistItemsCount();
      }
    });

    const setSearchResults = ((searchResult) => {
      productSearchResults.value = searchResult;
    });

    const setSearchLoading = ((searchLoadingValue) => {
      searchLoading.value = searchLoadingValue;
    });
    
    const setSearchTerm = ((searchTermValue) => {
      searchTerm.value = searchTermValue;
    });

    const clearSearch = (() => {
      clearSearchValue.value = true;
      searchTerm.value = "";
      hideSearchModal();
      productSearchResults.value = [];
    });

    const updateSearchClearvalue = (() => {
      clearSearchValue.value = false;
    });
    
    function getIncludedInMenuCategories(category: CategoryTree) {
      const categoryItem = JSON.parse(JSON.stringify(category));

      const children = categoryItem.children;
      delete categoryItem.children; // Removing unwanted data.
      
      if(categoryItem.include_in_menu) {
        featuredCategories.value.push(categoryItem);
      }
      
      if(children) {
        children.forEach((child) => {
          getIncludedInMenuCategories(child)
        });
      }
      
    }

    return {
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      featuredCategories,
      getCatLink,
      handleAccountClick,
      handleCartClick,
      storeLocatorClickEvent,
      isAuthenticated,
      isSearchModalOpen,
      isAllCatOpen,
      productSearchResults,
      searchLoading,
      searchTerm,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      showSearchModal,
      hideSearchModal,
      wishlistHasProducts,
      wishlistItemsQty,
      hasCurrencyToSelect,
      hasStoresToSelect,
      updateCatDropdownStatus,
      setSearchResults,
      setSearchLoading,
      setSearchTerm,
      openSupportLink,
      clearSearch,
      clearSearchValue,
      updateSearchClearvalue,
      getIncludedInMenuCategories,
      categoryList,
    };
  },
});
