const formatCurrency = (value: number | string, locale: string, options: Intl.NumberFormatOptions): string => {
  if (typeof value === 'string') {
    // eslint-disable-next-line no-param-reassign
    value = Number(value);
  }

  // Set minimumFractionDigits to 0 to remove the decimal and trailing zeros
  options.minimumFractionDigits = value % 1 === 0 ? 0 : 2;

  // eslint-disable-next-line no-param-reassign

  return new Intl.NumberFormat(locale, { style: 'currency', ...options }).format(value);
};

export default formatCurrency;
