












































import {
  defineComponent, PropType, ref,
} from '@nuxtjs/composition-api';

import { CategoryTree } from '~/modules/GraphQL/types';
import { useUiHelpers } from '~/composables';
import type { ComponentTemplateRef } from '~/types/componentTemplateRef';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import HeaderNavigationItem from './HeaderNavigationItem.vue';
import {
  SfList, SfButton, SfLink, SfIcon
} from '@storefront-ui/vue';

export default defineComponent({
  name: 'HeaderNavigation',
  components: {
    HeaderNavigationAllcategories: () => import('~/components/Header/Navigation/HeaderNavigationAllcategories.vue'),
    HeaderNavigationSubcategories: () => import('~/components/Header/Navigation/HeaderNavigationSubcategories.vue'),
    HeaderNavigationItem,
    SfButton,
    SfLink,
    SfIcon,
    SfList
  },
  directives: { clickOutside },
  props: {
    categoryTree: {
      type: Array as PropType<CategoryTree[]>,
      default: () => [],
    },
    featuredCategories: {
      type: Array as PropType<CategoryTree[]>,
      default: () => [],
    }
  },
  emits: ['updateCategoryDropdownStatus'],
  setup(props, { emit }) {
    const { getCatLink } = useUiHelpers();

    let currentCategory = ref<CategoryTree[] | null>();
    const hoverIndex = ref(null);
    const lvl0CatRefs = ref<ComponentTemplateRef[]>();
    const hasFocus = ref(false);
    const isAllCatOpen = ref(false);
    let lvl0CatFocusIdx = 0;
    let focusedElement : HTMLElement | null = null;

    const setCurrentCategory = (category: CategoryTree | null) => {
        currentCategory.value = category ? [category] : null;
    };

    const toggleSubcategories = (index: Number) => {
      hoverIndex.value = index;
    };

    const hasChildren = (category: CategoryTree) => Boolean(category?.children?.length);

    const setFocus = (event: MouseEvent & { target: HTMLElement }) => {
      focusedElement = event.target;
      lvl0CatFocusIdx = Number(event.target.dataset.index);
      hasFocus.value = true;
    };

    const navRight = () => {
      lvl0CatFocusIdx++;
      if (lvl0CatRefs.value[lvl0CatFocusIdx]) {
        lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
        focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
      } else {
        lvl0CatFocusIdx--;
      }
    };

    const navLeft = () => {
      lvl0CatFocusIdx--;
      if (lvl0CatRefs.value[lvl0CatFocusIdx]) {
        lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
        focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
      } else {
        lvl0CatFocusIdx++;
      }
    };

    const toggleBodyScroll = () => {
      if (document && !document.getElementsByTagName('html')[0].classList.contains('no-scroll')) {
        document.getElementsByTagName('html')[0].classList.add('no-scroll');
      } else {
        document.getElementsByTagName('html')[0].classList.remove('no-scroll');
      }
    };

    const toggleCategory = () => {
      isAllCatOpen.value = !isAllCatOpen.value;
      emit('updateCategoryDropdownStatus', isAllCatOpen.value);
      toggleBodyScroll();
    };

    return {
      getCatLink,
      setCurrentCategory,
      currentCategory,
      hasChildren,
      setFocus,
      lvl0CatRefs,
      navRight,
      navLeft,
      hasFocus,
      toggleSubcategories,
      toggleCategory,
      isAllCatOpen,
      hoverIndex
    };
  },
});
